import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  lng: "ar",
  fallbackLng: "ar",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
        translation: {
            besaudi: "Be Saudi",
            livesaudi: "Live Saudi",
            login: "Log in",
            logout: "Log out",
            signup: "Sign up",
            gallery: "Gallery",
            upload_instruction: "Click to upload or drag and drop",
            size_limit: "Max. File Size: 30MB",
            no_image: "No Image Generated",
            browse: "Browse File",
            male: "Male",
            female: "Female",
            generate: "Generate",
            terms_of_use: "By uploading the images, you agree with our Privacy Policy  and Terms of Use",
            tools: "TOOLS",
            image_generator: "Image Generator",
            image_options: "Image Options",
            features: "FEATURES",
            scale: "Scale",
            remove_background: "Remove Background",
            enhance: "Enhance",
            cutout: "Cut out",
            about: "ABOUT",
            pricing: "Pricing",
            guides: "Guides",
            faq: "FAQ",
            email: "Email",
            copyright: "Copyright",
            all_rights_reserved: "All rights reserved",
            title1: "Experience the traditional Saudi costumes with AI power!",
            subtitle1: "Try cool stuff of traditional Saudi costumes and enjoy the experience of being Saudi. Upload your photo now and give it a try!",
            title2: "Experience and live Saudi landmarks with AI power!",
            subtitle2: "Try cool stuff with live Saudi landmarks and enjoy the experience of being in Saudi. Upload your photo now and give it a try!",
            button_text: "Upload Image Now",
            usage_title: "How to Generate Saudi Uniform",
            usage_1_title: "Upload an Image",
            usage_1_content: "Simply click 'Upload an Image' and select a photo you want to use as the source image. Ensure only one face in the photo is of good quality.",
            usage_2_title: "Choose your option",
            usage_2_content: "Once your original image is ready, choose the desired gender, age and model. That means you will generate saudi uniform of your uploaded image with the selected option.",
            usage_3_title: "Generate Saudi Uniform",
            usage_3_content: "Click the Generate button to show AI its magic. Saudi Uniform will finish generating in a few seconds. Our AI does the job automatically while guaranteeing the best output.",
            usage_4_title: "Preview and Download",
            usage_4_content: "Bingo! Your Saudi uniform is generated to who you like! Preview the final result on the same page. Click Download to save the watermark-free picture to your device. Share it for fun!",
            advantage_title: "Saudi Uniform Generator Online, Simple, Fast, and Fun",
            advantage_1_title: "Easy-to-use",
            advantage_1_content: "All you need to do is upload an excellent face portrait and select the desired template. AI will generate Saudi Uniform smoothly without requiring manual editing.",
            advantage_2_title: "Privacy Protection",
            advantage_2_content: "We promise that all your uploaded images and other data will be protected well. No one will see these things except yourself. We make sure the Saudi uniform procedure is highly private.",
            advantage_3_title: "One-sec Saudi Uniform",
            advantage_3_content: "This online AI Saudi Uniform app can generate Saudi-uniform of anyone online. No installation, no ads, and no watermarks! Create your Saudi-uniform magic in fantastic style and without too much effort!",
            advantage_4_title: "Saudi-Uniform Options",
            advantage_4_content: "Choose from diverse options tailored to your preference. Pick from a wide range of stylized options. You can be anyone you want to be.",
            advantage_5_title: "Seamless Results",
            advantage_5_content: "Advanced AI algorithms power it. Saudi-uniform ensures seamless and realistic Saudi uniform. Anyone will naturally be put on Saudi uniform without introducing artifacts.",
            advantage_6_title: "For Funny Memes and Gifs",
            advantage_6_content: "We promise that all your uploaded images and other data will be protected well. No one will see these things except yourself. We make sure the Saudi uniform procedure is highly private.",

            login_title: "Sign in now",
            login_google: "Sign in with Google",
            email: "Email", 
            password: "Password", 
            signin: "Sign In",
            forgot_password: "Forgot Password",
            login_question: "Don't have an account?",

            signup_title: "Sign up for free",
            signup_google: "Sign up with google",
            name: "Name",
            signup_question: "Already have an account?",
            generating: "Generating..."
            
        }
    },
    ar: {
        translation: {
          besaudi: "كن سعوديا",
          livesaudi: "مباشر السعودية",
          login: "تسجيل الدخول",
          logout: "تسجيل الخروج",
          signup: "اشتراك",
          gallery: "المعرض",
          upload_instruction: "انقر للتحميل أو السحب والإفلات",
          size_limit: "الحد الأقصى لحجم الملف: 30 ميجا بايت",
          no_image: "لم يتم إنشاء أي صورة",
          browse: "تصفح الملف",
          male: "ذكر",
          female: "أنثى",
          generate: "يولد",
          terms_of_use: "من خلال تحميل الصور فإنك توافق على سياسة الخصوصية وشروط الاستخدام الخاصة بنا",
          tools: "أدوات",
          image_generator: "مولد الصور",
          image_options: "خيارات الصورة",
          features: "سمات",
          scale: "حجم",
          remove_background: "إزالة الخلفية",
          enhance: "يحسن",
          cutout: "قطع",
          about: "عن",
          pricing: "التسعير",
          guides: "أدلة",
          faq: "التعليمات",
          email: "بريد إلكتروني",
          copyright: "حقوق الطبع والنشر",
          all_rights_reserved: "جميع الحقوق محفوظة",
          title1: "استمتع بالأزياء السعودية التقليدية مع قوة الذكاء الاصطناعي!",
          subtitle1: "جرّب أشياء رائعة من الأزياء السعودية التقليدية واستمتع بتجربة كونك سعوديًا. قم بتحميل صورتك الآن وجربها!",
          title2: "استمتع بمعالم السعودية واستمتع بمشاهدتها مع قوة الذكاء الاصطناعي!",
          subtitle2: "جرّب أشياءً رائعة مع المعالم السعودية الحية واستمتع بتجربة التواجد في السعودية. حمّل صورتك الآن وجرّبها!",
          button_text: "قم بتحميل الصورة الآن",
          usage_title: "كيفية إنشاء الزي السعودي",
          usage_1_title: "تحميل صورة",
          usage_1_content: "ما عليك سوى النقر فوق \"تحميل صورة\" وتحديد الصورة التي تريد استخدامها كصورة المصدر. تأكد من أن وجهًا واحدًا فقط في الصورة يتمتع بجودة جيدة.",
          usage_2_title: "اختر خيارك",
          usage_2_content: "بمجرد أن تصبح صورتك الأصلية جاهزة، اختر الجنس والعمر والطراز المطلوبين. وهذا يعني أنك ستنشئ زيًا سعوديًا للصورة التي قمت بتحميلها باستخدام الخيار المحدد.",
          usage_3_title: "إنشاء الزي السعودي",
          usage_3_content: "انقر على زر \"إنشاء\" لإطلاق العنان لسحر الذكاء الاصطناعي. سينتهي فريق سعودي يونيفورم من إنشاء الصورة في غضون ثوانٍ قليلة. يقوم الذكاء الاصطناعي لدينا بهذه المهمة تلقائيًا مع ضمان أفضل النتائج.",
          usage_4_title: "معاينة وتحميل",
          usage_4_content: "بينجو! تم إنشاء الزي السعودي الخاص بك لمن تحب! يمكنك معاينة النتيجة النهائية على نفس الصفحة. انقر فوق تنزيل لحفظ الصورة الخالية من العلامة المائية على جهازك. شاركها من أجل المتعة!",
          advantage_title: "مولد الزي السعودي اون لاين، بسيط، سريع، وممتع",
          advantage_1_title: "سهل الاستخدام",
          advantage_1_content: "كل ما عليك فعله هو تحميل صورة وجه ممتازة واختيار القالب المطلوب، وسوف يقوم الذكاء الاصطناعي بإنشاء الزي السعودي بسلاسة دون الحاجة إلى التحرير اليدوي.",
          advantage_2_title: "حماية الخصوصية",
          advantage_2_content: "نعدك بأن جميع صورك وبياناتك الأخرى التي قمت بتحميلها ستكون محمية بشكل جيد. لن يرى هذه الأشياء أحد سواك. نحن نتأكد من أن إجراءات الزي السعودي تتمتع بخصوصية عالية.",
          advantage_3_title: "الزي السعودي في ثانية واحدة",
          advantage_3_content: "يمكن لهذا التطبيق عبر الإنترنت AI KSA Uniform إنشاء زي سعودي لأي شخص عبر الإنترنت. بدون تثبيت أو إعلانات أو علامات مائية! اصنع زيك السعودي السحري بأسلوب رائع وبدون بذل الكثير من الجهد!",
          advantage_4_title: "خيارات الزي السعودي الموحد",
          advantage_4_content: "اختر من بين خيارات متنوعة تناسب تفضيلاتك. اختر من بين مجموعة واسعة من الخيارات الأنيقة. يمكنك أن تكون أي شخص تريده.",
          advantage_5_title: "نتائج سلسة",
          advantage_5_content: "تعتمد هذه التقنية على خوارزميات الذكاء الاصطناعي المتقدمة. تضمن لك الزي السعودي أن يكون الزي السعودي سلسًا وواقعيًا. سيتمكن أي شخص من ارتداء الزي السعودي بشكل طبيعي دون إدخال أي تحف فنية..",
          advantage_6_title: "للحصول على صور متحركة وميمات مضحكة",
          advantage_6_content: "نعدك بأن جميع صورك وبياناتك الأخرى التي قمت بتحميلها ستكون محمية بشكل جيد. لن يرى هذه الأشياء أحد سواك. نحن نتأكد من أن إجراءات الزي السعودي تتمتع بخصوصية عالية.",


          login_title: "سجل دخولك الآن",
          login_google: "تسجيل الدخول باستخدام جوجل",
          email: "بريد إلكتروني", 
          password: "كلمة المرور", 
          signin: "تسجيل الدخول",
          forgot_password: "هل نسيت كلمة السر",
          login_question: "ليس لديك حساب؟",

          signup_title: "سجل مجانا",
          signup_google: "سجل باستخدام جوجل",
          name: "اسم",
          signup_question: "هل لديك حساب بالفعل؟",
          generating: "توليد..."
        }
    },
  },
});

export default i18n;